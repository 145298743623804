import React from "react"
import { Link } from "gatsby"

import Barre from "../components/barre";
import { GatsbySeo } from "gatsby-plugin-next-seo"

import '../css/style.css';
import '../css/home.css';

export default function Home() {
  return <div>
    <GatsbySeo
      language='fr'
      title='Mariée Création Passion / Contact'
      description='Mariée Création Passion situé sur la Côte d Azur près de Cannes, crée et réalise vos modèles exclusifs sur mesures'
    />
    <Barre />
    <div class="Titre container-fluid ">
      <div class="row">
        <div class="col-11 col-xl-5 offset-xl-1 offset-0">
          <h2>Cours de couture</h2>
          <p>
            <mark>« <span>Couture Création Passion</span> »</mark> c'est aussi des <b>cours</b> et{" "}
            <b>ateliers</b> de <b>couture</b> pour <b>adultes</b> et pour{" "}
            <b>Ados / enfants (dés 7 ans)</b> que vous soyez <b>débutant</b> ou bien{" "}
            <b>initié</b>,
            pour vous accompagner dans tous vos projets couture...
            <br />
            <br />
            <li>
              <b>Cours débutants</b> si vous souhaitez apprendre à utiliser parfaitement votre machine à coudre familiale ou votre surjeteuse ou bien connaître le béaba et rudiments de la couture…<br />
            </li>
            <br />
            <li>
              <b>Cours /Ateliers de perfectionnement</b>, destinés aux initiés qui souhaitent parfaire leurs
              connaissances avec un accompagnement personnalisé de leurs projets couture :
              Création / coupe / essayage / mise au point / confection de vêtements, layette, ameublement,
              retouches ou bien encore, « DIY », « upcycling », « customisation » de sa garde robe…<br />
            </li>
            <br />
            <li>
              <b>Cours de coupe / modélisme </b>: Pour apprendre à réaliser un patron à plat ou en volume sur
mannequin, savoir monter une toile, reproduire le patron d’un vêtement existant, utiliser un
patron de type <mark><i>« Burda »</i></mark>...<br />
            </li>
            <br />
            <b>Mise à disposition</b>  à<b> titre gracieux</b> d’une machine à coudre.<br />
            <br />
          </p>

          <h2>Deux Type de Cours :</h2>
          <p>
            <br />
            <li><b>COURS PARTICULIERS</b> personnalisés à votre <b>domicile</b>, jour et heure à votre convenance.</li>
            <br />Tarif : 31 € / heure.<br /><br/>
            Règlements par « CESU Avance Immédiate » acceptés pour bénéficier du crédit d’impôt
(anciennement Chèque Emploi Service).<br/><br/>
Déplacement <b>gratuit</b> dans le secteur : Vallée de la Siagne / Mandelieu / Cannes Bocca.<br/>
Pour les autres localités, frais de déplacement : 0,60€/km.<br />
            <br />
            <br />
            <li><b>COURS / ATELIERS COLLECTIFS</b> en petits groupes de 2/3 personnes pour
privilégier la convivialité et la qualité de l’apprentissage, pour vous accompagner dans vos
projets personnalisés de couture.</li><br />
            <b>-</b> Cours <u>adultes</u> : Le Vendredi après-midi et en soirée.<br/>
            Selon les demandes, possibilité de cours un autre jour de semaine.<br /><br/>
            <b>Certains samedis</b> de l'année, ateliers <mark>« <b>à thème</b> »</mark>, DIY, Zéro déchet…<br />
            <br />
            <br />
            <b>-</b> Cours <u>enfants et ados</u> : Le mercredi après-midi : 14h-16h<br />
            Tarif : 32€ les 2h de cours.<br/><br/>
            Réduction de -5% pour l’achat d’une Carte de fidélité de 5 cours de 2h soit : 150€.
            Cette carte permet d’assister aux cours au gré de vos envies et sans restriction de validité
            dans le temps.
            <br /><br />
            <b>-</b> <u>Ateliers & stages à thèmes</u><br/>
            Pour Enfants et Ados sont programmés au fil des
            saisons pendant les vacances scolaires, certains mercredis et samedis avec pour thèmes :
            Carnaval, cadeau fêtes des mères, Halloween, Noël…<br/><br/>
            Tarifs de ces ateliers : 26€ à 42€, variables en fonction de leur durée : de 1h à 3h.
          </p>
          <br/><br/>
          <h2>Carte Cadeau</h2>
          <p>
          <mark><span>« Couture Création Passion »</span></mark> vous propose également des « CARTES CADEAUX » :<br/>
           <b> Cours de couture particuliers à domicile</b>, à offrir à vos proches en toute occasion, sous forme de
           carte que vous pourrez personnaliser.<br/><br/>
            La couture est un moyen de se détendre, de faire émerger la créativité en soi. <br />
            Ce peut être, en plus, dans une <b>démarche écologique</b> pour redonner <mark>« une seconde vie »</mark> à sa
            garde-robe : la transformer, la <mark>« customiser »</mark> ou simplement réaliser ses propres retouches...<br /><br />
            Ces <b>cours particuliers</b> de couture sont destinés aussi bien aux <b>adultes</b> qu'aux <b>Ados</b> et <b>Enfants</b> dès 7 ans, aux <b>débutants</b> comme à ceux qui souhaitent <b>se perfectionner</b>, pour les accompagner dans
leurs projets ou simplement leur apprendre le « béaba » de la couture qui n’aura bientôt plus de
secret pour eux !<br />
            <br />
            Tarifs cartes cadeaux :<br /><br />
            <b>-</b> Cours de 1h30 : 49€<br />
            <b>-</b> Cours de 2h : 64€<br />
            <br />Tarif dégressif au-delà de ces durées, soit 31€ dès la 3° heure pour toute demande personnalisée de
            vos cartes cadeaux.<br/>
            Déplacement <b>gratuit</b> dans le secteur : Vallée de la Siagne / Mandelieu / Cannes Bocca.<br/>
            Pour les autres localités, frais de déplacement : 0,60€/km.<br />
            <br />
            <b>Mise à disposition</b> à <b>titre gracieux</b> d’une machine à coudre<br />
            <br />
            Je reste à votre disposition si vous souhaitez personnaliser votre cadeau.<br />

          </p>
        </div>
        <div class="col-11 col-xl-5 contact text-center mx-auto">
          <div class="ImgPresentation mb-5" id="Contact">
            <div class="Image mx-auto" id="imgContact"></div>
          </div>
          <a href="tel:0652508055">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
              <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
              <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
            </svg>Tel : 06 52 50 80 55
          </a>
          <br />
          <br />
          <a href="mailto:marieecreationpassion@gmail.com">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
            </svg>marieecreationpassion@gmail.com
          </a>
          <br />
          <br />
          <a>
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
              <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
              <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            </svg>125 chemin de la vignasse<br />
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="transparent" class="bi bi-geo-alt" viewBox="0 0 16 16"></svg>
            06550 La Roquette-Sur-Siagne
          </a>
          <div id="Artisan" >
            <div id="ImgArtisan" class="mt-5"></div>
            <div id="ImgFrance" class="mt-5 mb-2"></div>
            <p class="text-center mx-auto mb-5">N° SIRET : 91035650000017</p>
          </div>
        </div>
      </div>
    </div>
    <div class="p-lg-5"></div>

  </div>
}
